import React, { useState, useEffect } from "react";
import {
  Tag,
  Space,
  Row,
  Col,
  Button,
  Checkbox,
  notification,
  Input,
  Spin,
  DatePicker,
} from "antd";
import AppTable from "../../../components/AppTable/AppTable";
import AppModalWithGrid from "../../../components/AppModal/AppModalWithGrid";
import AppModalWithCancelForm from "../../../components/AppModal/AppModalWithCancelForm";
import axiosGet, { axiosPost } from "../../../helpers/AxiosRequest";
import { UrlConstants } from "../../../helpers/UrlConstantsHelper";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import "../Booking.scss";
import { CSVLink } from "react-csv";

const { Search } = Input;

const Enquiry = () => {
  let history: any = useHistory();
  const [gridModelVisible, setGridModalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [availableCabData, setAvailableCabData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [username, setUsername]: any = useState(
    localStorage.getItem("username")
  );
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const dateFormat = "DD/MM/YYYY";
  const [ButtonClicked, setIsButtonClicked] = useState("Today");

  const [fromdate, setfromdate] = useState(moment());
  const [todate, settodate] = useState(moment());
  let tempavialablecabs: any = [];
  const [Role, setRole]: any = useState(localStorage.getItem("roleid"));
  const [todayFlag, setTodayFlag] = useState(false);
  const [prevFlag, setPrevFlag] = useState(false);
  const [noofTrips, setNoofTrips] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [modalloading, setModalLoading] = useState(false);
  const [pagination, setPagination]: any = useState({
    current: 1,
    pageSize: 10,
  });

  const columns = [
    {
      title: " Enq.ID",
      dataIndex: "id",
      key: "Enquiry ID",
      fixed: "left",
      ellipsis: true,
      //sorter: (a: any, b: any) => a.id - b.id,
      width: "85px",
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
      fixed: "left",
      //ellipsis: true,
      // sorter: (a: any, b: any) => a.name - b.name,
      width: "200px",
    },
    // {
    //   title: "Mobile/Alternative",
    //   dataIndex: ["mobileno", "alternativemobileno"],
    //   key: "Mobile/Alternative",
    //   ellipsis: true,
    //   render: (text: any, record: any) => {
    //     return record.mobileno + " / " + record.alternativemobileno;
    //   },
    // },
    {
      title: "Mobile",
      dataIndex: "mobileno",
      key: "Mobile",
      fixed: "left",
      width: "110px",
      render: (text: any) => text?.slice(-10),
    },

    {
      title: "Date",
      dataIndex: "bookedtime",
      key: "Date",
      ellipsis: true,
      render: (text: any) => {
        return moment(text).format("DD/MM/YYYY");
      },
      width: "110px",
    },
    {
      title: "Time",
      dataIndex: "bookedtime",
      key: "Time",
      ellipsis: true,
      render: (text: any) => {
        return moment(text).format("hh:mm A");
      },
      width: "110px",
    },
    {
      title: "From Location",
      dataIndex: "fromloc",
      key: "From Location",
      //ellipsis: true,
      // sorter: (a: any, b: any) => a.fromloc - b.fromloc,
    },
    {
      title: "To Location",
      dataIndex: "toloc",
      key: "To Location",
      //ellipsis: true,
      // sorter: (a: any, b: any) => a.toloc - b.toloc,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "Remarks",
      //ellipsis: true,
    },
    {
      title: "Vehicle",
      dataIndex: "vehicletype",
      key: "Vehicle",
      ellipsis: true,
      width: "85px",
    },
    {
      title: "Fare Type",
      dataIndex: "faretype",
      key: "Fare Type",
      //ellipsis: true,
    },
    {
      title: "Credit",
      dataIndex: "iscredit",
      key: "Credit",
      ellipsis: true,
      render: (text: any, record: any) => {
        return (
          <div>
            <Checkbox defaultChecked={text} disabled />
          </div>
        );
      },
      width: "85px",
    },
    {
      title: "Goods",
      dataIndex: "goods",
      key: "Goods",
      //ellipsis: true,
    },

    {
      title: "Booked by",
      dataIndex: "enquiryby",
      key: "Booked by",
      ellipsis: true,
      /* render: (text: any, record: any) => {
        return " B: " + record.bookedby;
      }, */
      width: "110px",
    },
    {
      title: "Alternative",
      dataIndex: "alternativemobileno",
      key: "Alternative",
      width: "110px",
      render: (text: any) => text?.slice(-10),
    },

    {
      title: "City",
      dataIndex: "city",
      key: "City",
      ellipsis: true,
      // sorter: (a: any, b: any) => a.cityid - b.cityid,
      width: "120px",
    },
    {
      title: "Edit",
      dataIndex: "bookedby",
      key: "Booked by1",
      render: (text: any, record: any) => {
        return (
          <div>
            <Link
              style={{ marginRight: 16 }}
              to={{
                pathname: "/booking/editBooking",
                hash: new Date().getTime().toString(),
                state: { details: record },
              }}
            >
              <EditOutlined className="edit-booking" />
            </Link>
          </div>
        );
      },
      width: "60px",
    },
  ];

  const modalReAssignColumns = [
    {
      title: "CabId",
      dataIndex: "cabid",
      key: "cabid",
      fixed: "left",
      render: (text: any, record: any) => (
        <a onClick={() => onAssignBooking(null, record, null)}>{text}</a>
      ),
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: "Vehicle",
      dataIndex: "cabtype",
      key: "cabtype",
    },
    {
      title: "Driver Name",
      dataIndex: "drivername",
      key: "drivername",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "Mobile No",
      dataIndex: "drivermobileno",
      key: "drivermobileno",
      render: (text: any) => text?.slice(-10),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Total Trips",
      dataIndex: "totaltrip",
      key: "totaltrip",
    },
    {
      title: "Total Amount",
      dataIndex: "tripfare",
      key: "tripfare",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.tripfare === 0
                  ? "#f5d7c6"
                  : record.tripfare >= 1 && record.tripfare <= 499
                  ? "#f2ee9d"
                  : record.tripfare >= 499 && record.tripfare <= 1000
                  ? "##b0d4a0"
                  : "#fff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Home",
      dataIndex: "startlocation",
      key: "Home",
    },
    {
      title: "Minutes",
      dataIndex: "lastcompletedtime",
      key: "minutes",
      render: (text: any, record: any) => {
        let starttime = moment().startOf("day");
        return Math.round(
          moment.duration(moment().diff(moment(text))).asMinutes()
        );
      },
    },
  ];

  const showGridModal = () => {
    getAvailableCabList(selectedRowData);
    setGridModalVisible(true);
  };

  const hideModal = (isVisible: any) => {
    setGridModalVisible(isVisible);
    onDateSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
  };
  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
    console.log(rowInfo);
  };

  const onAssignBooking = (rowInfo: any, record: any, rowIndex: any) => {
    const options = {
      data: {
        cabid: record.cabid,
        enquiryid: selectedRowData[0].id,
        driverid: record.driverid,
        assignedby: username,
        emptyKM: record.distance,
      },
    };
    axiosPost(UrlConstants.enquiryAssignBooking, options)
      .then((resp: any) => {
        hideModal(false);
        //getEnquiryList();
        onDateSearch(
          fromdate.format("YYYY/MM/DD"),
          todate.format("YYYY/MM/DD"),
          pagination,
          SearchText
        );
        getAvailableCabList(selectedRowData);
        const notificationInfo = {
          header: "Enquiry Booking is Assigned Successfully",
          description:
            "You can see all the Live bookings by clicking below button",
          buttonText: "View Live Bookings",
          path: "/currentBooking",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch((error: any) => {
        if (error.response) {
        }
        const notificationInfo = {
          header: "Assign Booking Failed",
          description: "Error Occurred while assigning Booking",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const booknow = () => {
    console.log(selectedRowData[0].id);
    const options = {
      data: {
        branchid: branchid,
        enquiryid: selectedRowData[0].id,
        username: username,
      },
    };
    axiosPost(UrlConstants.enquirytonewbooking, options)
      .then((resp: any) => {
        onDateSearch(
          fromdate.format("YYYY/MM/DD"),
          todate.format("YYYY/MM/DD"),
          pagination,
          SearchText
        );

        const notificationInfo = {
          header: "Booking is Successfully",
          description:
            "You can see all the Live bookings by clicking below button",
          buttonText: "View Live Bookings",
          path: "/currentBooking",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch((error: any) => {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Booking Failed",
          description: "Error Occurred while Booking",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };

  const sendtoRAC = () => {
    console.log(selectedRowData[0].id);
    const options = {
      data: {
        bookid: selectedRowData[0].id,
      },
    };
    axiosPost(UrlConstants.newbookingtoRAC, options)
      .then((resp: any) => {
        //getEnquiryList();
        onDateSearch(
          fromdate.format("YYYY/MM/DD"),
          todate.format("YYYY/MM/DD"),
          pagination,
          SearchText
        );
        getAvailableCabList(selectedRowData);
        const notificationInfo = {
          header: "Booking is Assigned to RAC",
          description:
            "You can see all the RAC bookings by clicking below button",
          buttonText: "View RAC Bookings",
          path: "/racBooking",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch((error: any) => {
        const notificationInfo = {
          header: "Send Booking to RAC Failed",
          description: "Error Occurred while sending Booking to RAC",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };

  const onBookingNavigation = (path: any) => {
    history.push("/booking" + path);
  };

  const openNotificationWithIcon = (type: any, info: any) => {
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => onBookingNavigation(info.path)}
      >
        {info.buttonText}
      </Button>
    );
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
        btn,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  // const getAvailableCabs = () => {
  //   const uri = UrlConstants.getAvailableCabs + "?branchid=" + branchid;
  //   axiosGet(uri).then((resp: any) => {
  //     resp.forEach((e: any) => {
  //       e.key = e.cabid;
  //     });
  //     setAvailableCabData(resp);
  //   });
  // };

  const getAvailableCabList = (rowinfo: any) => {
    setModalLoading(true);
    const options = {
     
      params: {
        branchid: branchid,
        vehicletype: rowinfo[0].vehicletypeid,
        roleid: Role,
        fromlat: rowinfo[0].fromloclat,
        fromlong: rowinfo[0].fromloclong
      },
    };
 
    axiosGet(UrlConstants.getAvailableCabsNew, options).then((resp: any) => {
      setAvailableCabData(resp.data);
      setModalLoading(false);
     
    });
  };


  useEffect(() => {
    adayclick();
    //getAvailableCabs();
  }, []);

  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = currentBookingGridData.filter((value: any) => {
      if (value.fromloc === undefined || value.fromloc === null)
        value.fromloc = "";
      if (value.toloc === undefined || value.toloc === null) value.toloc = "";
      if (value.vehicletype === undefined || value.vehicletype === null)
        value.vehicletype = "";
      if (value.remarks === undefined || value.remarks === null)
        value.remarks = "";
      if (
        value.alternativemobileno === undefined ||
        value.alternativemobileno === null
      )
        value.alternativemobileno = "";
      if (value.bookedby === undefined || value.bookedby === null)
        value.bookedby = "";
      return (
        value.name.toLowerCase().includes(searchText.toLowerCase()) ||
        value.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.mobileno.toLowerCase().includes(searchText.toLowerCase()) ||
        value.alternativemobileno
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.fromloc.toLowerCase().includes(searchText.toLowerCase()) ||
        value.toloc.toLowerCase().includes(searchText.toLowerCase()) ||
        value.remarks.toLowerCase().includes(searchText.toLowerCase()) ||
        value.vehicletype.toLowerCase().includes(searchText.toLowerCase()) ||
        value.goods.toLowerCase().includes(searchText.toLowerCase()) ||
        value.bookedby.toLowerCase().includes(searchText.toLowerCase()) ||
        value.city.toLowerCase().includes(searchText.toLowerCase()) ||
        value.faretype.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };
  const onfromdatechange = (value: any) => {
    setfromdate(value);
    try {
      var fromdate = moment(value).format("YYYY/MM/DD");
      let todate = moment().format("YYYY/MM/DD");
      //DateSearch(fromdate, todate);
      setTodayFlag(false);
      setPrevFlag(false);
    } catch (ex) {
      console.log(ex);
    }
  };
  const ontodatechange = (value: any) => {
    settodate(value);
    try {
      var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
      let startdate = moment(fromdate).format("YYYY/MM/DD");
      //DateSearch(startdate, todate);
      setTodayFlag(false);
      setPrevFlag(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  const adayclick = (event: any = null) => {
    setIsButtonClicked("Today");
    var first = moment();
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));

    /*  var display_date = moment();
    var display_day = display_date.format('DD'); */

    //var new_date = moment().add('days', 2);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    //let display_todate = year + '-' + month + '-' + display_day;
    settodate(moment(todate));
    setTodayFlag(true);
    setPrevFlag(false);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };

  const twodayclick = () => {
    setIsButtonClicked("Yesday");
    var first = moment().add("days", -1);
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment().add("days", -1);
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(false);
    setPrevFlag(true);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };

  const monthclick = () => {
    setIsButtonClicked("Month");
    var first = moment();
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + "01";
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    console.log(fromdate);
    setTodayFlag(true);
    setPrevFlag(false);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };

  const weekclick = () => {
    setIsButtonClicked("Week");
    var first = moment().add("days", -moment().day());
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    console.log(fromdate);
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(true);
    setPrevFlag(false);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  const disabledFromDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment(todate).endOf("day");
  };

  const onDateSearch = (
    fromdate: any,
    todate: any,
    pagination: any,
    SearchText: any
  ) => {
    DateSearch(
      moment(fromdate).format("YYYY/MM/DD"),
      moment(todate).add("days", 1).format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
  };

  const DateSearch = (
    fromdate: any,
    todate: any,
    pagination: any,
    SearchText: any
  ) => {
    setIsLoading(true);
    const options: any = {
      params: {
        branchid: branchid,
        fromdate: fromdate,
        todate: todate,
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        filtervalue: SearchText,
      },
    };
    console.log(options);

    axiosGet(UrlConstants.getenquirybydate, options)
      .then((resp: any) => {
        resp.data.forEach((e: any) => {
          e.key = e.id;
        });
        console.log(resp);
        setIsLoading(false);
        setCurrentBookingGridData(resp.data);
        setcopyCurrentBookingGridData(resp.data);
        setTotalCount(resp.tablelength);

        setPagination({
          ...pagination,
          total: resp.tablelength,
        });
       
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
          setCurrentBookingGridData([]);
          setcopyCurrentBookingGridData([]);
        }
      });
  };
  const columnsForAll = columns.filter((col: any) => col.title !== "Mobile");

  const headersToExport = columnsForAll.map((column: any) =>
    column.title === "Time"
      ? { label: column.title, key: "Time" }
      : column.title === "Edit"
      ? { label: "", key: "" }
      : { label: column.title, key: column.dataIndex }
  );

  const dataToExport = currentBookingGridData.map((value: any) => ({
    ...value,
    //mobileno:value.mobileno.toString().concat(""),
    bookedtime: moment(value.bookedtime).format("DD/MM/YYYY"),
    iscredit: value.iscredit === false ? "No" : "Yes",
    Time: moment(value.bookedtime).format("hh:mm A"),
    //by: value.bookedby,
  }));
  const onPaginationchange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    setPagination(pagination);
    onDateSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
    console.log(pagination);
  };

  const onSearch = (value: any) => {
    console.log(value);
    setsearchText(value);
    onDateSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      value
    );
  };

  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">
      {(Role == 1 || Role == 2) && (
        <Row style={{ paddingBottom: 7 }}>
          <span style={{ fontSize: 'large', fontWeight: 'bold', fontStyle: 'Times New Roman' }}> From : {moment(fromdate).format('DD.MM.YYYY')}   To : {todayFlag? moment().format('DD.MM.YYYY') : (prevFlag ? moment().add('days', -1).format('DD.MM.YYYY') : moment(todate).format('DD.MM.YYYY'))}  </span>
        </Row>
      )}
         {(Role == 1 || Role == 2) && (
        <Row style={{ paddingBottom: 7 }}>
          <Col span={2}>
            <Button className={ButtonClicked === 'Today' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={adayclick}
            >
              Today
            </Button>
          </Col>

          <Col span={2} >
            <Button className={ButtonClicked == 'Yesday' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={twodayclick}
            >
              Prev
            </Button>
          </Col>
          <Col span={2} >
            <Button className={ButtonClicked === 'Week' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={weekclick}
            >
              Week
            </Button>
          </Col>
          <Col span={2} >
            <Button className={ButtonClicked === 'Month' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={monthclick}
            >
              Month
            </Button>
          </Col>
          <Col>


            <span style={{ fontSize: 'medium' }}> From:</span>

            <DatePicker

              value={fromdate}
              format={dateFormat}
              disabledDate={disabledFromDate}
              onChange={onfromdatechange} />
            <span style={{ fontSize: 'medium' }}> To:</span>


            <DatePicker
              //value={todate}
              value={todayFlag? moment() : (prevFlag ? moment().add('days', -1) : todate)}
              format={dateFormat}
              //disabledDate={disabledDate}
              onChange={ontodatechange} />


          </Col>
          <Col span={2} offset={1}>
                 <Button
                     className="booking-btn-blockSearch" 
                     onClick={()=>onDateSearch(fromdate, todate, pagination, SearchText)}
                     disabled={moment(fromdate).isAfter(moment(todate))}
                     >
                     Search
            </Button>
                     </Col>
          <Col offset={1}>
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}>Trips : </span></Col>
          <Col style={{ paddingLeft: '10px' }}>
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}> {totalCount}</span></Col>
        </Row>
      )}
        <Row>
        {(Role == 1 || Role == 2) && (
            <Col span={2} offset={1}>

              <CSVLink
                filename={"Enquiry.csv"}
                headers={headersToExport}
                data={dataToExport}
                className="btn btn-primary"
              >
                <Button
                  className="booking-btn-blockExport"
                // onClick={ExportToExcel}
                >
                  Export
                </Button>
              </CSVLink>
            </Col>
          )}
       
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-assign"
              disabled={!isRowSelected}
              onClick={showGridModal}
            >
              Assign
            </Button>
          </Col>

          <Col span={2} offset={1}>
            <Button
              className="booking-btn-cancel"
              disabled={!isRowSelected}
              onClick={booknow}
            >
              Book Now
            </Button>

          </Col>
          {(Role == 4 || Role == 5 || Role == 7) && (
          <Col offset={1}>
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}>Trips : </span></Col>)}
            {(Role == 4 || Role == 5 || Role == 7) && (
          <Col style={{ paddingLeft: '10px' }}>
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}> {totalCount}</span></Col>)}
            
          {Role == 1 ||  Role == 2 && (
            <Col span={2} offset={1}>
              <CSVLink
                filename={"Enquiry.csv"}
                headers={headersToExport}
                data={dataToExport}
                className="btn btn-primary"
              >
                <Button
                  className="booking-btn-blockExport"
                  //onClick={ExportToExcel}
                >
                  Export
                </Button>
              </CSVLink>
            </Col>
          )}
          <Col span={4} offset={10}>
            {/* <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} /> */}
            <Search placeholder="Search Text" onSearch={onSearch} enterButton />
          </Col>
        </Row>
      </div>

      <div>
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            enableRadioButton={true}
            onRadioButtonChange={onGridRowSelect}
            enablePagination={pagination}
            onchange={onPaginationchange}
            selectionType="radio"
            scroll={{ x: 2850, y: 480 }}
          ></AppTable>
        </Spin>
      </div>
      <AppModalWithGrid
        title="Assign Trip"
        visible={gridModelVisible}
        hideModal={hideModal}
        width={1000}
        isLoading={modalloading}
        selectedRowData={selectedRowData}
        modalGridColumns={modalReAssignColumns.filter(
          (item) =>
            moment().isSameOrAfter(moment("18:00:00", "HH:mm:ss")) ||
            item.dataIndex !== "startlocation"
        )}
        isAddbooking={false}
        modalGridData={availableCabData}
        onRowClickEnabled={false}
        onRowClick={onAssignBooking}
        customClass="new-booking-assign"
      />
    </div>
  );
};

export default Enquiry;
