import React, { useState, useEffect } from "react";
import {
  Tag,
  Space,
  Row,
  Col,
  Button,
  Checkbox,
  Input,
  Spin,
  DatePicker,
} from "antd";
import AppTable from "../../../components/AppTable/AppTable";
import AppModalWithGrid from "../../../components/AppModal/AppModalWithGrid";
import AppModalWithFormEditBooking from "../../../components/AppModal/AppModalWithFormEditBooking";
import AppModalWithCancelForm from "../../../components/AppModal/AppModalWithCancelForm";
import axiosGet from "../../../helpers/AxiosRequest";
import { UrlConstants } from "../../../helpers/UrlConstantsHelper";
import moment from "moment";
import "../Booking.scss";
import { CSVLink } from "react-csv";
import { EditOutlined } from "@ant-design/icons";
const { Search } = Input;

const CompletedBooking = () => {
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [ButtonClicked, setIsButtonClicked] = useState("Today");
  const [fromdate, setfromdate]: any = useState(moment());
  const [todate, settodate]: any = useState(moment());
  const dateFormat = "DD/MM/YYYY";
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [Role, setRole]: any = useState(localStorage.getItem("roleid"));
  const [todayFlag, setTodayFlag] = useState(false);
  const [prevFlag, setPrevFlag] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination]: any = useState({
    current: 1,
    pageSize: 10,
  });

  const columns = [
    {
      title: "Book ID",
      dataIndex: "id",
      key: "Booking ID",
      ellipsis: true,
      fixed: "left",
      //sorter: (a: any, b: any) => a.id - b.id,
      width: "85px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
      //ellipsis: true,
      fixed: "left",
      width: "200px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobileno",
      key: "Mobile",
      fixed: "left",
      width: "110px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              {Role.toString() == "1" ||
              Role.toString() == "2" ||
              Role.toString() == "4" ||
              Role.toString() == "7"
                ? text?.slice(-10)
                : "xxxxxxxxxx"}
            </div>
          ),
        };
      },
    },
    {
      title: "Cab ID",
      dataIndex: "cabid",
      key: "Cab ID",
      ellipsis: true,
      width: "85px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "Distance",
      ellipsis: true,
      width: "110px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "Duration",
      ellipsis: true,
      width: "110px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Fare",
      dataIndex: "fare",
      key: "Fare",
      ellipsis: true,
      render: (text: any, record: any) => {
        if (text != null && text != undefined && text != "" && text != 0)
          return {
            props: {
              style: {
                background:
                  record.vehicletype == "Bolero"
                    ? "#f5d7c6"
                    : record.vehicletype == "407"
                    ? "#fce6fc"
                    : record.vehicletype == "Eicher"
                    ? "#e1f6f7"
                    : "#ffffff",
              },
            },
            children: <div>{Math.round(text)}</div>,
          };
        else
          return {
            props: {
              style: {
                background:
                  record.vehicletype == "Bolero"
                    ? "#f5d7c6"
                    : record.vehicletype == "407"
                    ? "#fce6fc"
                    : record.vehicletype == "Eicher"
                    ? "#e1f6f7"
                    : "#ffffff",
              },
            },
            //children: <div>{text}</div>,
          };
      },
      width: "85px",
    },
    {
      title: "Date",
      dataIndex: "assignedtime",
      key: "Date",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{moment(text).format("DD/MM/YYYY")}</div>,
        };
      },
      width: "110px",
    },
    {
      title: "Time",
      dataIndex: ["bookedtime", "assignedtime", "completedtime"],
      //dataIndex:"assignedtime",
      key: "Time",
      ellipsis: true,
      // render: (text: any, record:any) => {

      //   return moment(text).format("hh:mm A");
      // },
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              <Row>
                B -{" "}
                {record.bookedtime != undefined
                  ? moment(record.bookedtime).format("hh:mm A")
                  : ""}
              </Row>
              <Row>
                A -{" "}
                {record.assignedtime != undefined
                  ? moment(record.assignedtime).format("hh:mm A")
                  : ""}
              </Row>
              <Row>
                C -{" "}
                {record.completedtime != undefined
                  ? moment(record.completedtime).format("hh:mm A")
                  : ""}
              </Row>
            </div>
          ),
        };
      },
      width: "110px",
    },
    {
      title: "From Location",
      dataIndex: "fromloc",
      key: "From Location",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      //ellipsis: true,
    },
    {
      title: "To Location",
      dataIndex: "toloc",
      key: "To Location",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      //ellipsis: true,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "Remarks",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      //ellipsis: true,
    },
    {
      title: "Vehicle",
      dataIndex: "vehicletype",
      key: "Vehicle",
      //ellipsis: true,
      width: "3%",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Fare Type",
      dataIndex: "faretype",
      key: "Fare Type",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      //ellipsis: true,
    },
    {
      title: "Credit",
      dataIndex: "iscredit",
      key: "Credit",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              <Checkbox defaultChecked={text} disabled />
            </div>
          ),
        };
      },
      width: "85px",
    },
    {
      title: "Goods",
      dataIndex: "goods",
      key: "Goods",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      //ellipsis: true,
    },
    {
      title: "OTP",
      dataIndex: "otp",
      key: "OTP",
      //ellipsis: true,
      width: "85px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Booked by",
      //dataIndex: ["bookedby", "assignedby", "completedby"],
      dataIndex: "bookedby",
      key: "Booked by",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              <Row>B - {record.bookedby} </Row>
              <Row>A - {record.assignedby} </Row>
              <Row>C - {record.completedby}</Row>
              {record.updatedby && <Row>U - {record.updatedby} </Row>}
              {record.reassignby && <Row>R - {record.reassignby} </Row>}
              {record.enquiryby && <Row>E- {record.enquiryby} </Row>}
            </div>
          ),
        };
      },
      width: "130px",
    },
    {
      title: "Vehicle Id",
      dataIndex: "vehicleid",
      key: "vehicle Id",
      //ellipsis: true,
      width: "110px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Alternative",
      dataIndex: "alternativemobileno",
      key: "Alternative",
      width: "110px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text?.slice(-10)}</div>,
        };
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "City",
      //ellipsis: true,
      width: "120px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype == "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype == "407"
                  ? "#fce6fc"
                  : record.vehicletype == "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    // {
    //   title: "Edit",
    //   dataIndex: "vehicleid",
    //   key: "Vehicle Id1",
    //   ellipsis: true,
    //   search: false,
    //   hidden: (Role.toString() == '1' || Role.toString() == '2' || Role.toString() == '4' || Role.toString() == '7') ? false : true,
    //   render: (text: any, record: any) => {
    //     return {
    //       props: {
    //         style: { background: record.vehicletype == "Bolero" ? "#f5d7c6" : record.vehicletype == "407" ? "#fce6fc" : record.vehicletype == "Eicher" ? "#e1f6f7" : "#ffffff" }
    //       },
    //       children:
    //         <div>
    //           <span
    //             style={{ width: 1, color: "black" }}
    //           >
    //             {/* <EditOutlined className="edit-booking" /> */}
    //             <a onClick={() => showEditModal(record)}><EditOutlined className="edit-booking" /></a>
    //           </span>
    //         </div>
    //     };
    //   },
    //   width: "60px",
    // }
  ];

  const onfromdatechange = (value: any) => {
    setfromdate(value);
    try {
      var fromdate = moment(value).format("YYYY/MM/DD");
      let todate = moment().format("YYYY/MM/DD");
      //DateSearch(fromdate, todate);
      setTodayFlag(false);
      setPrevFlag(false);
      //setfromdate(value);
    } catch (ex) {
      console.log(ex);
    }
  };
  const ontodatechange = (value: any) => {
    settodate(value);
    try {
      let startdate = moment(fromdate).format("YYYY/MM/DD");
      //DateSearch(startdate, todate);
      setTodayFlag(false);
      setPrevFlag(false);

      //settodate(moment(todate));
    } catch (ex) {
      console.log(ex);
    }
  };

  const adayclick = (event: any = null) => {
    setIsButtonClicked("Today");
    var first = moment();
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(true);
    setPrevFlag(false);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };

  const twodayclick = () => {
    setIsButtonClicked("Yesday");
    var first = moment().add("days", -1);
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    var new_date = moment().add("days", -1);
    //var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(false);
    setPrevFlag(true);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };

  const monthclick = () => {
    setIsButtonClicked("Month");
    var first = moment();
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + "01";
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(true);
    setPrevFlag(false);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };

  const weekclick = () => {
    setIsButtonClicked("Week");
    var first = moment().add("days", -moment().day());
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(true);
    setPrevFlag(false);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  const disabledFromDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment(todate).endOf("day");
  };

  const onDateSearch = (
    fromdate: any,
    todate: any,
    pagination: any,
    SearchText: any
  ) => {
    DateSearch(
      moment(fromdate).format("YYYY/MM/DD"),
      moment(todate).add("days", 1).format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
  };

  const DateSearch = (
    fromdate: any,
    todate: any,
    pagination: any,
    SearchText: any
  ) => {
    setIsLoading(true);
    const options: any = {
      params: {
        branchid: branchid,
        fromdate: fromdate,
        todate: todate,
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        filtervalue: SearchText,
      },
    };
    axiosGet(UrlConstants.getCompletedbookingbydate, options)
      .then((resp: any) => {
        resp.data.forEach((e: any) => {
          e.key = e.id;
          e.fare = isNaN(e.fare) ? e.fare.replace(/[^0-9]/g, "") : e.fare;
        });
        setIsLoading(false);
        setCurrentBookingGridData(resp.data);
        setcopyCurrentBookingGridData(resp.data);
        setTotalCount(resp.tablelength);
        setTotalAmount(resp.totalamount);
        setPagination({
          ...pagination,
          total: resp.tablelength,
        });
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
          setCurrentBookingGridData([]);
          setcopyCurrentBookingGridData([]);
        }
      });
  };

  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };
  useEffect(() => {
    adayclick();
  }, []);
  const onSearchchange = (e: any) => {
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = currentBookingGridData.filter((value: any) => {
      if (value.remarks === undefined || value.remarks === null)
        value.remarks = "";
      if (value.refferedby === undefined || value.refferedby === null)
        value.refferedby = "";
      if (value.vehicleid === undefined || value.vehicleid === null)
        value.vehicleid = "";
      if (
        value.alternativemobileno === undefined ||
        value.alternativemobileno === null
      )
        value.alternativemobileno = "";
      return (
        value.name
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        (Role.toString() == "1" || Role.toString() == "2"
          ? value.mobileno
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          : null) ||
        value.alternativemobileno
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.fromloc
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.toloc
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.remarks
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.vehicletype
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.goods
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.bookedby
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.city
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.faretype
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.cabid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.otp.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.assignedby
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.vehicleid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.distance
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.duration
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.fare
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.completedby
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };

  const columnsForAll = columns.filter((col: any) => col.title != "Mobile");

  const headersToExport = columnsForAll.map((column: any) =>
    /* (Role == 1 ? columns: columnsForAll).map((column:any) => */
    column.title == "Time"
      ? { label: column.title, key: "time" }
      : column.title == "Booked by"
      ? { label: column.title, key: "by" }
      : column.title == "Booked by"
      ? { label: column.title, key: "by" }
      : { label: column.title, key: column.dataIndex }
  );

  const dataToExport = currentBookingGridData.map((value: any) => ({
    ...value,
    mobileno: value.mobileno.toString().concat(""),
    assignedtime: moment(value.assignedtime).format("DD/MM/YYYY"),
    iscredit: value.iscredit == false ? "No" : "Yes",
    time: "B-".concat(
      moment(value.bookedtime).format("hh:mm A"),
      "\n",
      "A-",
      moment(value.assignedtime).format("hh:mm A"),
      "\n",
      "C-",
      moment(value.completedtime).format("hh:mm A")
    ),
    by: "B-".concat(
      value.bookedby,
      "\n",
      "A-",
      value.assignedby,
      "\n",
      "C-",
      value.completedby
    ),
  }));
  const showEditModal = (selectedRow: any) => {
    setEditModalVisible(true);
    setSelectedRowData(selectedRow);
  };

  const hideEditModal = (isVisible: any) => {
    setEditModalVisible(isVisible);
    //refresh();
    onDateSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
  };

  /* const refresh = () => {
    setIsLoading(true);
    const options: any = {
      params: {
        branchid: branchid,
        fromdate: fromdate.format('YYYY/MM/DD'),
        todate: todate.format('YYYY/MM/DD')
      },
    };
    axiosGet(UrlConstants.getCompletedbookingbydate, options).then((resp: any) => {
      console.log(resp);
        resp.forEach((e: any) => {
          e.key = e.id;
        });
        setIsLoading(false);
        setCurrentBookingGridData(resp);
        setcopyCurrentBookingGridData(resp);
    }).catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
          setCurrentBookingGridData([]);
          setcopyCurrentBookingGridData([]);
        }
      });
  };
   */
  const exportToExcelHandler = () => {
    const options: any = {
      params: {
        branchid: branchid,
        fromdate: moment(fromdate).format("YYYY-MM-DD"),
        todate: moment(todate).add("days", 1).format("YYYY-MM-DD"),
      },
    };
    axiosGet(UrlConstants.exportgetCompletedbookingbydate, options)
      .then((resp: any) => {
        onExportToExcel(resp.url);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };

  const onExportToExcel = (path: any) => {
    window.open(path);
  };
  const onPaginationchange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    setPagination(pagination);
    onDateSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
  };
  const onSearch = (value: any) => {
    setsearchText(value);
    onDateSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      value
    );
  };

  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">
        {(Role == 1 || Role == 2) && (
          <Row style={{ paddingBottom: 7 }}>
            <span
              style={{
                fontSize: "large",
                fontWeight: "bold",
                fontStyle: "Times New Roman",
              }}
            >
              {" "}
              From : {moment(fromdate).format("DD.MM.YYYY")} To :{" "}
              {
                todayFlag
                  ? moment().format("DD.MM.YYYY")
                  : /* (prevFlag ? moment().add('days', -1).format('DD.MM.YYYY') :  */ moment(
                      todate
                    ).format("DD.MM.YYYY") /* ) */
              }{" "}
            </span>
          </Row>
        )}
        {(Role == 1 || Role == 2) && (
          <Row style={{ paddingBottom: 10 }}>
            <Col span={2}>
              <Button
                className={
                  ButtonClicked === "Today"
                    ? "booking-btn-buttonTrue"
                    : "booking-btn-buttonFalse"
                }
                onClick={adayclick}
              >
                Today
              </Button>
            </Col>

            <Col span={2}>
              <Button
                className={
                  ButtonClicked === "Yesday"
                    ? "booking-btn-buttonTrue"
                    : "booking-btn-buttonFalse"
                }
                onClick={twodayclick}
              >
                Prev
              </Button>
            </Col>
            <Col span={2}>
              <Button
                className={
                  ButtonClicked === "Week"
                    ? "booking-btn-buttonTrue"
                    : "booking-btn-buttonFalse"
                }
                onClick={weekclick}
              >
                Week
              </Button>
            </Col>
            <Col span={2}>
              <Button
                className={
                  ButtonClicked === "Month"
                    ? "booking-btn-buttonTrue"
                    : "booking-btn-buttonFalse"
                }
                onClick={monthclick}
              >
                Month
              </Button>
            </Col>
            <Col offset={7} span={3} style={{ textAlign: "right" }}>
              <span style={{ fontSize: "large", fontWeight: "bold" }}>
                Trips :{" "}
              </span>
              {/* </Col> */}
              {/* <Col style={{ paddingLeft: '10px' }}> */}
              <span style={{ fontSize: "large", fontWeight: "bold" }}>
                {" "}
                {totalCount}
              </span>
            </Col>
            <Col offset={1} span={5}>
              <span style={{ fontSize: "large", fontWeight: "bold" }}>
                Amount :{" "}
              </span>
              {/* </Col>
          <Col style={{ paddingLeft: '10px' }}> */}
              {/* <span style={{ fontSize: 'large', fontWeight: 'bold' }}> {Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.fare); }, 0) + Number.EPSILON) * 100) / 100)}</span> */}
              <span style={{ fontSize: "large", fontWeight: "bold" }}>
                {" "}
                {Math.round(totalAmount)}{" "}
              </span>
            </Col>
          </Row>
        )}
        <Row>
          {(Role == 1 || Role == 2) && (
            <Col flex="auto">
              <span style={{ fontSize: "medium" }}> From:</span>

              <DatePicker
                value={fromdate}
                format={dateFormat}
                disabledDate={disabledFromDate}
                onChange={onfromdatechange}
              />
              <span style={{ fontSize: "medium" }}> To:</span>

              <DatePicker
                //value={todate}
                value={
                  todayFlag
                    ? moment()
                    : prevFlag
                    ? moment().add("days", -1)
                    : todate
                }
                format={dateFormat}
                disabledDate={disabledDate}
                onChange={ontodatechange}
              />
            </Col>
          )}
          {(Role == 1 || Role == 2) && (
            <Col span={2}>
              <Button
                className="booking-btn-blockSearch"
                onClick={() =>
                  onDateSearch(fromdate, todate, pagination, SearchText)
                }
                disabled={moment(fromdate).isAfter(moment(todate))}
              >
                Search
              </Button>
            </Col>
          )}

          {(Role == 1 || Role == 2) && (
            <Col span={2} offset={1}>
              {/* <CSVLink
                filename={"CompletedBooking.csv"}
                headers={headersToExport}
                data={dataToExport}
                className="btn btn-primary"
              >  */}
              <Button
                className="booking-btn-blockExport"
                onClick={exportToExcelHandler}
              >
                Export
              </Button>
              {/* </CSVLink>  */}
            </Col>
          )}
          {(Role == 1 || Role == 2) && (
            <Col span={4} offset={6}>
              {/* <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} /> */}
              <Search
                placeholder="Search Text"
                onSearch={onSearch}
                enterButton
              />
            </Col>
          )}
          {Role != 1 && Role != 2 && (
            <>
              <Col offset={15}>
                <span style={{ fontSize: "large", fontWeight: "bold" }}>
                  Trips :{" "}
                </span>
              </Col>
              <Col style={{ paddingLeft: "10px" }}>
                <span style={{ fontSize: "large", fontWeight: "bold" }}>
                  {" "}
                  {totalCount}
                </span>
              </Col>
              <Col span={4} offset={2}>
                {/* <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} /> */}
                <Search
                  placeholder="Search Text"
                  onSearch={onSearch}
                  enterButton
                />
              </Col>
            </>
          )}
        </Row>
      </div>

      <div>
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            //enableRadioButton={true}
            //onRadioButtonChange={onGridRowSelect}
            enablePagination={pagination}
            onchange={onPaginationchange}
            //selectionType="radio"
            scroll={{ x: 2850, y: 480 }}
          ></AppTable>
          <AppModalWithFormEditBooking
            title="Edit Booking"
            visible={editModalVisible}
            hideModal={hideEditModal}
            selectedRowData={selectedRowData}
          />
        </Spin>
      </div>
    </div>
  );
};

export default CompletedBooking;
