import React, { useState, useEffect } from "react";
import { Row, Col, Button, notification, Input, Spin } from "antd";
import AppTable from "../../../components/AppTable/AppTable";
import AppModalWithGrid from "../../../components/AppModal/AppModalWithGrid";
import AppModalWithForm from "../../../components/AppModal/AppModalWithForm";
import AppModalWithCancelForm from "../../../components/AppModal/AppModalWithCancelForm";
import axiosGet, { axiosPost } from "../../../helpers/AxiosRequest";
import { UrlConstants } from "../../../helpers/UrlConstantsHelper";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";

const { Search } = Input;

const CurrentBooking = () => {
  let history: any = useHistory();
  const [gridModelVisible, setGridModalVisible] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [cancelformModalVisible, setCancelFormModalVisible] = useState(false);
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [availableCabData, setAvailableCabData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [Role, setRole]: any = useState(localStorage.getItem("roleid"));
  const [username, setUsername]: any = useState(
    localStorage.getItem("username")
  );
  const [SearchText, setsearchText] = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [modalloading, setModalLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  let tempavialablecabs: any = [];
  const [pagination, setPagination]: any = useState({
    current: 1,
    pageSize: 10,
  });

  const columns = [
    {
      title: "Book ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      ellipsis: true,
      //sorter: (a: any, b: any) => a.id - b.id,
      search: false,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.tripstatuscode === 1
                  ? "#ffff00"
                  : record.tripstatuscode === 2
                  ? "#a4c2f4"
                  : record.tripstatuscode === 3
                  ? "#93c47d"
                  : record.tripstatuscode === 4
                  ? "#f4cccc"
                  : record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      width: "110px",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
      //ellipsis: true,
      //
      fixed: "left",
      search: true,
      render: (text: any, record: any) => {
        return {
          props: {
            //style: { background: record.tripstatuscode===1 ? "#ffff00" : record.tripstatuscode===2 ? "#a4c2f4" : record.tripstatuscode===3 ? "#93c47d" : record.tripstatuscode===4 ? "#f4cccc" : "" }
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      //render: (text: any) => <a>{text}</a>,
      width: "200px",
    },
    {
      title: "Mobile",
      dataIndex: "mobileno",
      ellipsis: true,
      key: "Mobile",
      fixed: "left",
      search: false,
      // render: (text: any, record: any) => {
      //   return record.mobileno + " / " + record.alternativemobileno;
      // },
      render: (text: any, record: any) => {
        return {
          props: {
            //style: { background: record.tripstatuscode===1 ? "#ffff00" : record.tripstatuscode===2 ? "#a4c2f4" : record.tripstatuscode===3 ? "#93c47d" : record.tripstatuscode===4 ? "#f4cccc" : "" }
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text?.slice(-10)}</div>,
        };
      },
      width: "110px",
    },

    {
      title: "Cab ID",
      dataIndex: "cabid",
      key: "Cab ID",
      ellipsis: true,
      search: false,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      width: "85px",
      //render: (text: any) => <a>{text}</a>,
      onHeaderCell: (column: any) => {
        return { width: "85px" };
      },
    },

    {
      title: "Date",
      dataIndex: "assignedtime",
      key: "Date",
      ellipsis: true,
      search: false,
      render: (text: any, record: any) => {
        if (text !== undefined || text !== "")
          return {
            props: {
              style: {
                background:
                  record.vehicletype === "Bolero"
                    ? "#f5d7c6"
                    : record.vehicletype === "407"
                    ? "#fce6fc"
                    : record.vehicletype === "Eicher"
                    ? "#e1f6f7"
                    : "#ffffff",
              },
            },
            children: <div>{moment(text).format("DD/MM/YYYY")}</div>,
          };
        else
          return {
            props: {
              style: {
                background:
                  record.vehicletype === "Bolero"
                    ? "#f5d7c6"
                    : record.vehicletype === "407"
                    ? "#fce6fc"
                    : record.vehicletype === "Eicher"
                    ? "#e1f6f7"
                    : "#ffffff",
              },
            },
            //children: <div>{text}</div>,
          };
      },
      width: "110px",
    },
    {
      title: "Time",
      dataIndex: ["bookedtime", "assignedtime", "tripstart"],
      key: "Time",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              <Row>
                B -{" "}
                {record.bookedtime !== undefined
                  ? moment(record.bookedtime).format("hh:mm A")
                  : ""}
              </Row>
              <Row>
                A -{" "}
                {record.assignedtime !== undefined
                  ? moment(record.assignedtime).format("hh:mm A")
                  : ""}
              </Row>
              {record.tripstart && (
                <Row>
                  S -{" "}
                  {record.tripstart !== undefined
                    ? moment(record.tripstart).format("hh:mm A")
                    : ""}
                </Row>
                
              )}
                {record.tripend && (
                <Row>
                  E -{" "}
                  {record.tripend !== undefined
                    ? moment(record.tripend).format("hh:mm A")
                    : ""}
                </Row>
                
              )}

            </div>
          ),
        };
      },
      width: "110px",
    },
    {
      title: "From Location",
      dataIndex: "fromloc",
      key: "From Location",
      //ellipsis: true,
      search: false,
      // sorter: (a: any, b: any) => a.fromloc - b.fromloc,
      //render: (text: any) => <a>{text}</a>,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "To Location",
      dataIndex: "toloc",
      key: "To Location",
      //ellipsis: true,
      search: false,
      // sorter: (a: any, b: any) => a.toloc - b.toloc,
      //render: (text: any) => <a>{text}</a>,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "Remarks",
      //ellipsis: true,
      search: false,
      //render: (text: any) => <a>{text}</a>,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Vehicle",
      dataIndex: "vehicletype",
      key: "Vehicle",
      ellipsis: true,
      search: false,
      //  render: (text: any) => <a>{text}</a>, //=> {
      //     return text;
      //record.vechicledetail.length > 0 && record.vechicledetail[0].Vechicle
      width: "85px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Fare Type",
      dataIndex: "faretype",
      key: "Fare Type",
      //ellipsis: true,
      search: false,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Credit",
      dataIndex: "iscredit",
      key: "Credit",
      ellipsis: true,
      search: false,
      width: "85px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      //render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Goods",
      dataIndex: "goods",
      key: "Goods",
      //ellipsis: true,
      search: false,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      //render: (text: any) => <a>{text}</a>,
    },
    {
      title: "OTP",
      dataIndex: "otp",
      key: "OTP",
      ellipsis: true,
      search: false,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
      //render: (text: any) => <a>{text}</a>,
      width: "85px",
    },
    {
      title: "Booked by",
      dataIndex: ["bookedby", "assignedby"],
      key: "Booked by",
      ellipsis: true,
      search: false,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              <Row>B - {record.bookedby} </Row>
              <Row>A - {record.assignedby} </Row>
              {record.updatedby && <Row>U - {record.updatedby} </Row>}
              {record.reassignby && <Row>R - {record.reassignby} </Row>}
              {record.enquiryby && <Row>E- {record.enquiryby} </Row>}
            </div>
          ),
        };
      },
      width: "110px",
    },
    {
      title: "Vehicle Id",
      dataIndex: "vehicleid",
      key: "Vehicle Id",
      search: false,
      //ellipsis: true,
      // render: (text: any, record: any) => {
      //   //return record.vechicledetail.length > 0 && record.vechicledetail[0].id;
      // },
      width: "110px",
      onHeaderCell: (column: any) => {
        return { width: "110px" };
      },
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Alternative",
      dataIndex: "alternativemobileno",
      ellipsis: true,
      key: "Alternative",
      search: false,
      width: "110px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text?.slice(-10)}</div>,
        };
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "City",
      ellipsis: true,
      search: false,
      // sorter: (a: any, b: any) => a.cityid - b.cityid,
      //render: (text: any) => <a>{text}</a>,
      width: "120px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Edit",
      dataIndex: "vehicleid",
      key: "Vehicle Id1",
      ellipsis: true,
      search: false,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              <Link
                style={{ marginRight: 16 }}
                to={{
                  pathname: "/booking/editBooking",
                  hash: new Date().getTime().toString(),
                  state: { details: record, isCurrentBooking: true },
                }}
              >
                <EditOutlined className="edit-booking" />
              </Link>
            </div>
          ),
        };
      },
      width: "60px",
      // render: (text: any, record: any) => {
      //   //return record.vechicledetail.length > 0 && record.vechicledetail[0].id;
      // },
    },
  ];

  const modalReAssignColumns = [
    {
      title: "CabId",
      dataIndex: "cabid",
      key: "cabid",
      fixed: "left",
      render: (text: any, record: any) => (
        <a onClick={() => onReAssignBooking(null, record, null)}>{text}</a>
      ),
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: "Vehicle",
      dataIndex: "cabtype",
      key: "cabtype",
    },
    {
      title: "Driver Name",
      dataIndex: "drivername",
      key: "drivername",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "Mobile No",
      dataIndex: "drivermobileno",
      key: "drivermobileno",
      render: (text: any) => text?.slice(-10),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Total Trips",
      dataIndex: "totaltrip",
      key: "totaltrip",
    },
    {
      title: "Total Amount",
      dataIndex: "tripfare",
      key: "tripfare",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.tripfare === 0
                  ? "#f5d7c6"
                  : record.tripfare >= 1 && record.tripfare <= 499
                  ? "#f2ee9d"
                  : record.tripfare >= 499 && record.tripfare <= 1000
                  ? "##b0d4a0"
                  : "#fff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Home",
      dataIndex: "startlocation",
      key: "Home",
    },
    {
      title: "Minutes",
      dataIndex: "lastcompletedtime",
      key: "minutes",
      render: (text: any, record: any) => {
        let starttime = moment().startOf("day");
        return Math.round(
          moment.duration(moment().diff(moment(text))).asMinutes()
        );
      },
    },
  ];

  const showGridModal = () => {
    getAvailableCabList(selectedRowData);
    setGridModalVisible(true);
  };

  const hideModal = (isVisible: any) => {
    setGridModalVisible(isVisible);
  };

  const showFormModal = () => {
    setFormModalVisible(true);
  };

  const hideFormModal = (isVisible: any) => {
    getCurrentBookingList(pagination, SearchText);
    setFormModalVisible(isVisible);
  };

  const showCancelFormModal = () => {
    setCancelFormModalVisible(true);
  };

  const hideCancelFormModal = (isVisible: any) => {
    getCurrentBookingList(pagination, SearchText);
    setCancelFormModalVisible(isVisible);
  };

  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setIsComplete(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };
  const onReAssignBooking = (rowInfo: any, record: any, rowIndex: any) => {
    const options = {
      data: {
        oldcabid: selectedRowData[0].cabid,
        bookid: selectedRowData[0].id,
        cabid: record.cabid,
        driverid: record.driverid,
        reassignby: username,
        emptyKM: record.distance,
      },
    };
    axiosPost(UrlConstants.reassignBooking, options)
      .then((resp: any) => {
        hideModal(false);
        const notificationInfo = {
          header: "Booking is Re-Assigned to " + record.cabid,
          description: "Booking is successfully Re-Assigned",
          // buttonText: "View Live Bookings",
          // path: "/currentBooking",
        };
        const notificationInfo1: any = {
          header:
            resp[0].sms === false
              ? "SMS sending failed"
              : "SMS Sent Successfully",
          description:
            resp[0].sms === false
              ? "SMS sending failed"
              : "SMS Sent Successfully",
        };
        openNotificationWithIcon("Success", notificationInfo1);
        openNotificationWithIcon(
          resp[0].sms === false ? "Error" : "Success",
          notificationInfo
        );
        getCurrentBookingList(pagination, SearchText);
      })
      .catch((error: any) => {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Booking Re-Assign Failed",
          description: "Error Occurred while Re-Assigning Booking",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };

  const onBookingNavigation = (path: any) => {
    history.push("/booking" + path);
  };

  const openNotificationWithIcon = (type: any, info: any) => {
    const btn = info.buttonText && (
      <Button
        type="primary"
        size="small"
        onClick={() => onBookingNavigation(info.path)}
      >
        {info.buttonText}
      </Button>
    );
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
        btn,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  useEffect(() => {
    getCurrentBookingList(pagination, SearchText);
    //getAvailableCabList();
  }, []);

  const getCurrentBookingList = (pagination: any, SearchText: any) => {
    setIsLoading(true);
    const options: any = {
      params: {
        branchid: branchid,
        // limit: pagination.pageSize,
        // skip: (pagination.current - 1) * pagination.pageSize,
        filtervalue: SearchText,
      },
    };
    axiosGet(UrlConstants.getCurrentBooking, options)
      .then((resp: any) => {
        resp.data.forEach((e: any) => {
          e.key = e.id.toString();
        });
    setIsLoading(true);
    // const uri = UrlConstants.getCurrentBooking + "?branchid=" + branchid;
    // axiosGet(uri)
    //   .then((resp: any) => {
    //     resp.forEach((e: any) => {
    //       e.key = e.id.toString();
    //     });
        setIsLoading(false);
        setCurrentBookingGridData(resp.data);
        setcopyCurrentBookingGridData(resp.data);
        setTotalCount(resp.tablelength);
       ;
      })
      .catch(function (error: any) {
        setIsLoading(false);
        setCurrentBookingGridData([]);
        setcopyCurrentBookingGridData([]);
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };

  const getAvailableCabList = (rowinfo: any) => {
    setModalLoading(true);
    const options = {
      params: {
        branchid: branchid,
        vehicletype: rowinfo[0].vehicletypeid,
        roleid: Role,
        fromlat: rowinfo[0].fromloclat,
        fromlong: rowinfo[0].fromloclong,
      },
    };

    axiosGet(UrlConstants.getAvailableCabsNew, options).then((resp: any) => {
      setAvailableCabData(resp.data);
      setModalLoading(false);
    });
  };

  const onPaginationchange = (pagination: any) => {
    setPagination(pagination);
    getCurrentBookingList(pagination, SearchText);
  };
  const onSearch = (value: any) => {
    setsearchText(value);
    getCurrentBookingList(pagination, value);
  };

  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">
        <Row>
          {(Role == 1 || Role == 2 || Role == 4 || Role == 7) && (
            <Col span={2}>
              <Button
                className="booking-btn-reassign"
                disabled={!isRowSelected}
                onClick={showGridModal}
              >
                ReAssign
              </Button>
            </Col>
          )}
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-complete"
              disabled={!isRowSelected}
              onClick={showFormModal}
            >
              Complete
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-cancel"
              disabled={!isRowSelected}
              onClick={showCancelFormModal}
            >
              Cancel
            </Button>
          </Col>
          <Col offset={5} span={1}>
            <span style={{ fontSize: "large", fontWeight: "bold" }}>
              Trips :{" "}
            </span>
          </Col>

          <Col style={{ paddingLeft: "10px" }} span={1}>
            <span style={{ fontSize: "large", fontWeight: "bold" }}>
              {" "}
              {totalCount}
            </span>
          </Col>
          <Col span={4} offset={2}>
            {/* <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} /> */}
            <Search placeholder="Search Text" onSearch={onSearch} enterButton />
          </Col>
        </Row>
      </div>

      <div>
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            rowClassName={(record: any) => record.tripstatus}
            enableRadioButton={true}
            onRadioButtonChange={onGridRowSelect}
            selectionType="radio"
            scroll={{ x: 2900, y: 480 }}
            enablePagination={true}
            defaultPageSize={100}
          ></AppTable>
        </Spin>
      </div>
      <AppModalWithGrid
        title="ReAssign Trip"
        visible={gridModelVisible}
        hideModal={hideModal}
        width={1000}
        isLoading={modalloading}
        selectedRowData={selectedRowData}
        modalGridColumns={modalReAssignColumns.filter(
          (item) =>
            moment().isSameOrAfter(moment("18:00:00", "HH:mm:ss")) ||
            item.dataIndex != "startlocation"
        )}
        isAddbooking={false}
        modalGridData={availableCabData}
        onRowClickEnabled={false}
        onRowClick={onReAssignBooking}
        customClass="new-booking-assign"
      />
      <AppModalWithForm
        title="Complete Booking"
        visible={formModalVisible}
        hideModal={hideFormModal}
        selectedRowData={selectedRowData}
      />
      <AppModalWithCancelForm
        title="Cancel Booking"
        visible={cancelformModalVisible}
        hideModal={hideCancelFormModal}
        selectedRowData={selectedRowData}
      />
    </div>
  );
};

export default CurrentBooking;
