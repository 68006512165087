import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Input,
  notification,
  Dropdown,
  Menu,
  Select,
} from "antd";
import "./DriverAttendance.scss";
import AppTable from "../../components/AppTable/AppTable";
import AppModalWithApproveDriverAttendance from "../../components/AppModal/AppModalDriverAttendanceApprove";
import AppModalWithLogoutDriverAttendance from "../../components/AppModal/AppModalWithLogoutDriverAttendance";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import AppModalDriverAttendanceLeave from "../../components/AppModal/AppModalDriverAttendanceLeave";
import AppModalDriverAttendanceBlock from "../../components/AppModal/AppModalDriverAttendanceBlock";
import AppModalDriverAttendanceAppManager from "../../components/AppModal/AppModalDriverAttendanceAppManager";
import moment from "moment";
import DriverStatusUpdateModal from "../../components/AppModal/DriverStatusUpdateModal";
import { DownOutlined } from "@ant-design/icons";

const { Option } = Select;
const DriverAttendance = () => {
  const [approveDriverAttendanceVisible, setApproveDriverAttendanceVisible] =
    useState(false);
  const [logoutDriverAttendanceVisible, setLogoutDriverAttendanceVisible] =
    useState(false);
  const [leaveDriverAttendanceVisible, setLeaveDriverAttendanceVisible] =
    useState(false);
  const [blockDriverAttendanceVisible, setBlockDriverAttendanceVisible] =
    useState(false);
  const [
    appManagerDriverAttendanceVisible,
    setAppManagerDriverAttendanceVisible,
  ] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  const [searchFromLocationsList, setSearchFromLocationsList] = useState([]);
  const [selectedFromLocation, setSelectedFromLocation]: any = useState({});
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isblocked, setisblocked] = useState(true);
  const [isleaveok, setisleaveok] = useState(true);
  const [nologout, setnologout] = useState(true);
  const [selectedRowData, setSelectedRowData]: any = useState({});
  const [TotalCount, setTotalRecordCount] = useState(0);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [isLoading, setIsLoading] = useState(false);
  const [SearchText, setsearchText]: any = useState("");
  const [Logoutcount, setlogoutcount] = useState([]);
  const [Leavecount, setleavecount] = useState([]);
  const [Blockcount, setblockcount] = useState([]);
  const [Availablecount, setavbailalecount] = useState([]);
  const [Ontrip, setontripcount] = useState([]);
  const [driverAttendanceList, setDriverAttendanceList] = useState([]);
  const [attendanceManager, setAttendanceManager] = useState("");
  const [disableblock, setdisableblock] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [roleid, setRoleid]: any = useState(localStorage.getItem("roleid"));
  const [username, setUsername]: any = useState(
    localStorage.getItem("username")
  );
  const [revertType, setRevertType] = useState("");
  const [cabLocation, setcabLocation] = useState([]);
  const onFromLocationChange = (value: any, options: any, cabid: any) => {
    getLocationupdate(options.others, cabid);
  };
  const onFromLocationSearch = (value: any) => {
    if (value) {
      var filtered = locationsList.filter((x: any) =>
        String(x.PlaceName.toLowerCase()).startsWith(value.toLowerCase())
      );
      setSearchFromLocationsList(filtered);
    } else {
      setSearchFromLocationsList([]);
    }
  };
  const getLocationupdate = (value: any, cabid: any) => {
    const options = {
      data: {
        cabid: cabid,
        piclat: value.Latitude,
        piclon: value.Longitude,
        present_location: value.PlaceName,
        locationupdatedby: username,
      },
    };
    axiosPost(UrlConstants.locationupdate, options).then((resp: any) => {});
  };
  const getLocations = (value: any) => {
    const options = {
      params: {
        cityid: value,
      },
    };
    axiosGet(UrlConstants.getLocations, options).then((resp: any) => {
      setLocationsList(resp);
    });
  };
  const columns = [
    {
      title: "Cab ID",
      dataIndex: "cabid",
      key: "Cab ID",
      ellipsis: true,
      fixed: "left",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: <div>{text}</div>,
        };
      },
      //render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Vehicle",
      dataIndex: "vechicletype",
      key: "Vehicle",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Driver Name",
      dataIndex: "drivername",
      key: "Driver Name",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobileno",
      key: "Mobile",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Date",
      dataIndex: "dateandtime",
      key: "Time",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: <div>{moment(text).format("DD/MM/YYYY")}</div>,
        };
      },
    },
    {
      title: "Time",
      dataIndex: "dateandtime",
      key: "Time",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: <div>{moment(text).format(" hh:mm A")}</div>,
        };
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "Location",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children:
            (roleid == 1 || roleid == 2 || roleid == 7 || roleid == 4) &&
            record.statuscode == 11 ? (
              <>
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(value, options) =>
                    onFromLocationChange(value, options, record.cabid)
                  }
                  defaultValue={record.location}
                  value={cabLocation[record.cab_id]}
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showArrow={false}
                  onSearch={onFromLocationSearch}
                  placeholder="Search Location"
                  notFoundContent={null}
                  style={{ width: "100%" }}
                >
                  {searchFromLocationsList.map((item: any) => (
                    <Option key={item._id} value={item.ID} others={item}>
                      {item.PlaceName}
                    </Option>
                  ))}
                </Select>
              </>
            ) : (
              <div>{text}</div>
            ),
        };
      },
    },

    {
      title: "Status",
      dataIndex: "driverstatus",
      key: "Status",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },

    {
      title: "App Status",
      dataIndex: "appstatus",
      key: "App Status",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: (
            <div>
              <Row>
                {record.statuscode !== 16 &&
                record.statuscode !== 17 &&
                record.statuscode !== 19 &&
                record.statuscode !== 14 &&
                record.statuscode !== 15
                  ? record.appstatus
                  : ""}
                {record.statuscode === 16 ? record.blockreason : ""}
                {record.statuscode === 17 || record.statuscode === 19
                  ? record.hours
                  : ""}
                {record.statuscode === 14 || record.statuscode === 15
                  ? record.leavereason
                  : ""}
              </Row>
            </div>
          ),
        };
      },
    },
    {
      title: "Vehicle Id",
      dataIndex: "vehicleid",
      key: "Vehicle Id",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },

    {
      title: "Login",
      dataIndex: ["loginby", "openingKM"],
      key: "Loginby",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: (
            <div>
              <Row>U - {record.loginby} </Row>
              <Row>KM - {record.openingKM} </Row>
            </div>
          ),
        };
      },
    },
    {
      title: "Logout",
      dataIndex: ["logoutby", "closingKM"],
      key: "Time",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.statuscode == 1
                  ? "#ea9999"
                  : record.statuscode == 2
                  ? "#ffd966"
                  : record.statuscode == 3
                  ? "#ffd966"
                  : record.statuscode == 4
                  ? "#ffd966"
                  : record.statuscode == 5
                  ? "#a5e684"
                  : record.statuscode == 10
                  ? "#ffffff"
                  : record.statuscode == 11
                  ? "#a5e684"
                  : record.statuscode == 13
                  ? "#3d85c6"
                  : record.statuscode == 14
                  ? "#B6917C"
                  : record.statuscode == 15
                  ? "#c27ba0"
                  : record.statuscode == 16 &&
                    record.blockreason == "Pending payment"
                  ? "#9e9e9e"
                  : record.statuscode == 16 &&
                    record.blockreason != "Pending payment"
                  ? "#e06666"
                  : record.statuscode == 17
                  ? "#efcede"
                  : record.statuscode == 19
                  ? "#efcede"
                  : record.statuscode == 18
                  ? "#d5a6bd"
                  : "",
            },
          },
          children: (
            <div>
              <Row>U - {record.logoutby ? record.logoutby : "Auto Logout"}</Row>
              <Row>KM - {record.closingKM} </Row>
            </div>
          ),
        };
      },
    },
  ];
  const onSearchchange = (e: any) => {
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = driverAttendanceList.filter((value: any) => {
      if (value.cabid === undefined || value.cabid === null) value.cabid = "";
      if (value.vechicletype === undefined || value.vechicletype === null)
        value.vechicletype = "";
      if (value.drivername === undefined || value.drivername === null)
        value.drivername = "";
      if (value.mobileno === undefined || value.mobileno === null)
        value.mobileno = "";
      if (value.location === undefined || value.location === null)
        value.location = "";
      if (value.vehicleid === undefined || value.vehicleid === null)
        value.vehicleid = "";

      return (
        value.cabid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.vechicletype.toLowerCase().includes(searchText.toLowerCase()) ||
        value.drivername.toLowerCase().includes(searchText.toLowerCase()) ||
        value.mobileno
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.location.toLowerCase().includes(searchText.toLowerCase()) ||
        value.vehicleid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
    setcopyCurrentBookingGridData(filteredData);
    setFilteredData(filteredData);
  };

  const showApproveDriverAttendanceFormModal = () => {
    setApproveDriverAttendanceVisible(true);
  };

  const hideApproveDriverAttendanceFormModal = (isVisible: any) => {
    setApproveDriverAttendanceVisible(isVisible);
    refresh();
  };
  const showLogoutDriverAttendanceFormModal = () => {
    setLogoutDriverAttendanceVisible(true);
  };

  const hideLogoutDriverAttendanceFormModal = (isVisible: any) => {
    setLogoutDriverAttendanceVisible(isVisible);
    refresh();
  };

  const showLeaveDriverAttendanceFormModal = () => {
    setLeaveDriverAttendanceVisible(true);
  };
  const hideLeavetDriverAttendanceFormModal = (isVisible: any) => {
    setLeaveDriverAttendanceVisible(isVisible);
    refresh();
  };
  const showBlockDriverAttendanceFormModal = () => {
    setBlockDriverAttendanceVisible(true);
  };
  const hideBlockDriverAttendanceFormModal = (isVisible: any) => {
    setBlockDriverAttendanceVisible(isVisible);
    refresh();
  };
  const showAppManagerDriverAttendanceFormModal = () => {
    setAppManagerDriverAttendanceVisible(true);
  };
  const hideAppManagerDriverAttendanceFormModal = (isVisible: any) => {
    setAppManagerDriverAttendanceVisible(isVisible);
  };

  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);

    if (rowInfo.length > 0) {
      if (
        rowInfo[0].statuscode == 2 ||
        rowInfo[0].statuscode == 3 ||
        rowInfo[0].statuscode == 4 ||
        rowInfo[0].statuscode == 11 ||
        rowInfo[0].statuscode == 13 ||
        rowInfo[0].statuscode == 16 ||
        rowInfo[0].statuscode == 17
      ) {
        setisblocked(true);
      } else setisblocked(false);
      if (rowInfo[0].statuscode === 11 || rowInfo[0].statuscode === 13) {
        setnologout(false);
      } else setnologout(true);
      if (rowInfo[0].statuscode === 10 || rowInfo[0].statuscode === 14) {
        setisleaveok(false);
      } else setisleaveok(true);

      if (
        rowInfo[0].statuscode === 2 ||
        rowInfo[0].statuscode === 3 ||
        rowInfo[0].statuscode === 4
      )
        setdisableblock(true);
      else setdisableblock(false);
      setSelectedRowData(rowInfo[0]);
    }
  };

  useEffect(() => {
    getLocations(branchid);
    refresh();
  }, []);

  const refresh = () => {
    setIsLoading(true);
    const options = {
      params: {
        branchid: branchid,
      },
    };

    axiosGet(UrlConstants.getDriverAttendanceList, options)
      .then((resp: any) => {
        try {
          var cabloctemp: any = [];
          resp.data.forEach((e: any) => {
            e.key = e.cabid;
            let obj = { [e.cabid]: e.PlaceName };
            cabloctemp.push(obj);
          });
          setIsLoading(false);
          setcabLocation(cabloctemp);
          setTotalRecordCount(resp.tablelength);
          setDriverAttendanceList(resp.data);
          setcopyCurrentBookingGridData(resp.data);
          setlogoutcount(resp.logoutcount);
          setavbailalecount(resp.availablecount);
          setleavecount(resp.leavecount);
          setblockcount(resp.blockedcount);
          setontripcount(resp.ondutycount);
        } catch (ex) {
          console.log(ex);
        }
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const showOnLeaveDrivers = () => {
    let filteredData = driverAttendanceList.filter(
      (value: any) => value.driverstatus == "On leave"
    );
    setcopyCurrentBookingGridData(
      filteredData.sort((a: any, b: any) => a.cabid.localeCompare(b.cabid))
    );
  };
  const showLoggedoutDrivers = () => {
    let filteredData = driverAttendanceList.filter(
      (value: any) => value.driverstatus == "Logout"
    );
    setcopyCurrentBookingGridData(
      filteredData.sort((a: any, b: any) => a.cabid.localeCompare(b.cabid))
    );
  };
  const showBlockedDrivers = () => {
    let filteredData = driverAttendanceList.filter(
      (value: any) => value.driverstatus == "Blocked"
    );
    setcopyCurrentBookingGridData(
      filteredData.sort((a: any, b: any) => a.cabid.localeCompare(b.cabid))
    );
  };
  const showAvailableDrivers = () => {
    let filteredData = driverAttendanceList.filter(
      (value: any) => value.statuscode == 11
    );
    setcopyCurrentBookingGridData(
      filteredData.sort((a: any, b: any) => a.cabid.localeCompare(b.cabid))
    );
  };
  const showOntripDrivers = () => {
    let filteredData = driverAttendanceList.filter(
      (value: any) =>
        value.statuscode == 2 || value.statuscode == 3 || value.statuscode == 4
    );
    setcopyCurrentBookingGridData(
      filteredData.sort((a: any, b: any) => a.cabid.localeCompare(b.cabid))
    );
  };

  const resumeduty = () => {
    const options: any = {
      data: {
        cabid: selectedRowData.cabid,
      },
    };

    axiosPost(UrlConstants.updateDriverStatus, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Driver Resumed Duty",
          description: "Cabid " + selectedRowData.cabid + " status updated",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Driver Resume Failed",
          description: "Error Occurred while resuming Driver status",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
    refresh();
  };
  const showAllDrivers = () => {
    // let totalDrivers: any = [
    //   ...noofDriversAvailable,
    //   ...noofDriversOntrip,
    //   ...noofDriversLoggedout,
    //   ...noofDriversOnleave,
    //   ...noofDriversBlocked,
    // ];
    setcopyCurrentBookingGridData(driverAttendanceList);
  };
  const onAttendanceManagerChange = (e: any) => {
    if (e.key == 1) {
      showConfirmModal();
      setRevertType("login");
      //revertlogin();
    } else if (e.key == 2) {
      showConfirmModal();
      setRevertType("logout");
      //revertlogout();
    } else if (e.key == 4) {
      /* else if(value==3)
      revertleave(); */
      showConfirmModal();
      setRevertType("ontrip");
    }
    setAttendanceManager(e.key);
  };

  const revertleave = () => {
    const options: any = {
      data: {
        cabid: selectedRowData.cabid,
      },
    };

    axiosPost(UrlConstants.revertlogout, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Logout Reverted  Successfully",
          description: "Cabid " + selectedRowData.cabid + " leave reverted",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Logout Revert Failed",
          description: "Error Occurred while Reverting Logout",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  const showConfirmModal = () => {
    setConfirmModalVisible(true);
  };
  const hideConfirmModal = (isVisible: any) => {
    setConfirmModalVisible(isVisible);
    refresh();
  };
  const columnsForAll = columns;
  const headersToExport = columnsForAll.map((column: any) =>
    column.title == "Time"
      ? { label: column.title, key: "Time" }
      : column.title == "Date"
      ? { label: column.title, key: "Date" }
      : column.title == "Booked by"
      ? { label: column.title, key: "by" }
      : column.title == "Booked by"
      ? { label: column.title, key: "by" }
      : { label: column.title, key: column.dataIndex }
  );

  const dataToExport = driverAttendanceList.map((value: any) => ({
    ...value,
    mobileno: value.mobileno.toString().concat(""),
    Date: moment(value.dateandtime).format("DD/MM/YYYY"),
    Time: moment(value.dateandtime).format("hh:mm A"),
  }));
  const menu = (
    <Menu onClick={onAttendanceManagerChange}>
      <Menu.Item
        key="1"
        disabled={
          (roleid != 1 || roleid != 2 || roleid != 7) &&
          selectedRowData.statuscode !== 11 &&
          selectedRowData.statuscode !== 1
        }
      >
        Revert Login
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="2"
        disabled={
          (roleid != 1 || roleid != 2 || roleid != 7) &&
          selectedRowData.statuscode !== 10 &&
          selectedRowData.statuscode !== 13
        }
      >
        Revert Logout
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="4"
        disabled={
          (roleid != 1 || roleid != 2 || roleid != 4 || roleid != 7) &&
          selectedRowData.statuscode !== 2 &&
          selectedRowData.statuscode !== 3 &&
          selectedRowData.statuscode !== 4
        }
      >
        Available
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {roleid != 8 && (
        <Row gutter={16} style={{ display: "flex" }}>
          <Col span={2}>
            <Button
              className="driver-attendance-btn-approve"
              disabled={
                isblocked ||
                (roleid == 5 &&
                  moment().isAfter(moment("10:00:00", "HH:mm:ss")))
              }
              onClick={showApproveDriverAttendanceFormModal}
            >
              Approve
            </Button>
          </Col>
          <Col span={2}>
            <Button
              className="driver-attendance-btn-logout"
              disabled={nologout}
              onClick={showLogoutDriverAttendanceFormModal}
            >
              Logout
            </Button>
          </Col>
          <Col span={2}>
            <Button
              className="driver-attendance-btn-leave"
              disabled={isleaveok}
              onClick={showLeaveDriverAttendanceFormModal}
            >
              Leave
            </Button>
          </Col>
          <Col span={2}>
            <Button
              className="driver-attendance-btn-block"
              onClick={showBlockDriverAttendanceFormModal}
              disabled={disableblock}
            >
              Block
            </Button>
          </Col>

          <Col span={5}>
            {(roleid == 1 || roleid == 2 || roleid == 4 || roleid == 7) && (
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button className="driver-attendance-btn-attmanager">
                  Attendance Manager <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </Col>
          {(selectedRowData?.statuscode == 18 ||
            selectedRowData?.statuscode == 19 ||
            selectedRowData?.statuscode == 17) && (
            <Col span={2}>
              <Button
                className="driver-attendance-btn-block"
                onClick={resumeduty}
              >
                Resume
              </Button>
            </Col>
          )}
          {/* <Col span={5}>
          <Button
            className="driver-attendance-btn-appmanager"
            disabled={isblocked}
            onClick={showAppManagerDriverAttendanceFormModal}
          >
            Application Manager
          </Button>
        </Col> */}
          <Col span={4} className="driver-attendance-total-count">
            <span style={{ fontWeight: "bold" }}>
              Total Attendance: {TotalCount}
            </span>
          </Col>
        </Row>
      )}
      <Row gutter={16} style={{ display: "flex", padding: "7px 0" }}>
        <Col span={3}>
          <Button
            className="driver-attendance-btn-loggedout"
            onClick={showLoggedoutDrivers}
          >
            Logout - {Logoutcount}
          </Button>
        </Col>
        <Col span={3}>
          <Button
            className="driver-attendance-btn-onleave"
            onClick={showOnLeaveDrivers}
          >
            On Leave - {Leavecount}
          </Button>
        </Col>
        <Col span={3}>
          <Button
            className="driver-attendance-btn-blocked"
            onClick={showBlockedDrivers}
          >
            Blocked - {Blockcount}
          </Button>
        </Col>
        <Col span={3}>
          <Button
            className="driver-attendance-btn-onduty"
            onClick={showAvailableDrivers}
          >
            Available - {Availablecount}
          </Button>
        </Col>
        <Col span={3}>
          <Button
            className="driver-attendance-btn-ontrip"
            onClick={showOntripDrivers}
          >
            On Trip - {Ontrip}
          </Button>
        </Col>
        <Col span={3}>
          <Button
            className="driver-attendance-btn-all"
            onClick={showAllDrivers}
          >
            All - {TotalCount}
          </Button>
        </Col>
        {roleid == 8 && (
          <Col span={3}>
            <Button
              className="driver-attendance-btn-block"
              onClick={showBlockDriverAttendanceFormModal}
              disabled={disableblock}
            >
              Block
            </Button>
          </Col>
        )}

        <Col span={3}>
          <Input
            placeholder="Search Text"
            className="search"
            onChange={onSearchchange}
          />
        </Col>
      </Row>
      <Row className="driver-attendance-table-row" style={{ display: "unset" }}>
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            enableRadioButton={true}
            onRadioButtonChange={onGridRowSelect}
            enablePagination={true}
            defaultPageSize={20}
            selectionType="radio"
          ></AppTable>
        </Spin>
      </Row>
      <AppModalWithApproveDriverAttendance
        title="Approve"
        visible={approveDriverAttendanceVisible}
        hideModal={hideApproveDriverAttendanceFormModal}
        selectedRowData={selectedRowData}
      />
      <AppModalWithLogoutDriverAttendance
        title="Logout"
        visible={logoutDriverAttendanceVisible}
        hideModal={hideLogoutDriverAttendanceFormModal}
        selectedRowData={selectedRowData}
      />
      <AppModalDriverAttendanceLeave
        title="Leave"
        visible={leaveDriverAttendanceVisible}
        hideModal={hideLeavetDriverAttendanceFormModal}
        selectedRowData={selectedRowData}
      />
      <AppModalDriverAttendanceBlock
        title="Block"
        visible={blockDriverAttendanceVisible}
        hideModal={hideBlockDriverAttendanceFormModal}
        width={700}
        selectedRowData={selectedRowData}
        // modalGridColumns={modalReAssignColumns}
        // modalGridData={[]}
      />
      <AppModalDriverAttendanceAppManager
        title="App Manager"
        visible={appManagerDriverAttendanceVisible}
        hideModal={hideAppManagerDriverAttendanceFormModal}
        width={700}
        selectedRowData={selectedRowData}
      />
      <DriverStatusUpdateModal
        visible={confirmModalVisible}
        hideModal={hideConfirmModal}
        selectedRowData={selectedRowData}
        revertType={revertType}
      />
    </div>
  );
};

export default DriverAttendance;
