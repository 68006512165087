import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Input, Spin } from "antd";
import AppTable from "../AppTable/AppTable";
import "./AppModal.scss";

const AppModalWithGrid = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [SearchText, setsearchText]: any = useState("");
  const [copymodalGridData, setCopyModalGridData] = useState([]);

  useEffect(() => {
    if (props.selectedRowData && props.selectedRowData.length > 0) {
      setSelectedRowInfo(props.selectedRowData[0]);
    }
  }, [props.selectedRowData]);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    setCopyModalGridData(props.modalGridData);
  }, [props.modalGridData]);

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const onSearchchange = (e: any) => {
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };

  const globalSearch = (searchText: any) => {
    let filteredData = props.modalGridData.filter((value: any) => {
      if (value.cabid === undefined || value.cabid === null) value.cabid = "";
      if (value.distance === undefined || value.distance === null)
        value.distance = "";
      if (value.cabtype === undefined || value.cabtype === null)
        value.cabtype = "";
      if (value.drivername === undefined || value.drivername === null)
        value.drivername = "";
      if (value.drivermobileno === undefined || value.drivermobileno === null)
        value.drivermobileno = "";
      if (value.location === undefined || value.location === null)
        value.location = "";
      if (value.totaltrip === undefined || value.totaltrip === null)
        value.totaltrip = "";
      if (value.tripfare === undefined || value.tripfare === null)
        value.tripfare = "";

      return (
        value.cabid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.distance
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.cabtype
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.drivername
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.drivermobileno
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.location
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.totaltrip
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.tripfare
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
    setCopyModalGridData(filteredData);
  };

  return (
    <div>
      {/* <Button type="primary" onClick={() => showModal()}>
        Open Modal
    </Button> */}
      <Modal
        destroyOnClose={true}
        className="custom-modal-grid"
        width={props.width || 550}
        title={props.title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        {props.isAddbooking !== true && (
          <div>
            <Row className="custom-modal-grid-booking-info">
              <Col span={8}>Booking Id: {selectedRowInfo.id}</Col>
              <Col span={8}>From: {selectedRowInfo.fromloc}</Col>
              <Col span={8}>To: {selectedRowInfo.toloc}</Col>
            </Row>
            <Row style={{ padding: "0 0 0.5rem 0" }}>
              <Col span={5} offset={19}>
                <Input
                  placeholder="Search Text"
                  value={SearchText}
                  onChange={onSearchchange}
                />
              </Col>
            </Row>
          </div>
        )}
        <Row className="custom-modal-grid-booking-grid">
          <Spin tip="Loading..." spinning={props.isLoading || false}>
            <AppTable
              columns={props.modalGridColumns}
              data={copymodalGridData}
              onRowClickEnabled={props.onRowClickEnabled}
              onRowClick={props.onRowClick}
              customClass={props.customClass}
              scroll={{ x: true }}
              pagination={
                {
                  //     total: {props.length} // total count returned from backend
                }
              }
            ></AppTable>
          </Spin>
        </Row>
      </Modal>
    </div>
  );
};

export default AppModalWithGrid;
